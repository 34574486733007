import { Component, ViewEncapsulation } from '@angular/core';
import { AppSettings } from './app.settings';
import { Settings } from './app.settings.model';
import { NavigationEnd, Router } from '@angular/router';
import { GlobalComponent } from './views/global/global.component';
import { loginDetails } from './views/login/login.model';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {
  public settings: Settings;
  constructor(public glob: GlobalComponent, public router: Router, public appSettings: AppSettings,
  ) {
    this.settings = this.appSettings.settings;
    this.router.navigateByUrl('/messageadmin');
  }
  ngOnInit() {
    let userdetails: loginDetails = JSON.parse(localStorage.getItem("UserDetails"));
    let LoginTYpeCON = localStorage.getItem('LoginTYpeCON');
    this.glob.roledetail = LoginTYpeCON;
    if (userdetails != null) {
      let profiledetails = JSON.parse(localStorage.getItem('UserDetails'));
      this.glob.profileInfo = JSON.parse(JSON.stringify(profiledetails));
      this.glob.profileInfo.refreshToken = profiledetails.refreshToken;
      if (profiledetails.UserName == null || profiledetails.UserName == undefined) {
        this.glob.profileInfo.loginUserId = profiledetails.sessionUser.userName;
      }

      if (profiledetails.apiToken != null) {
        this.glob.profileInfo.apiToken = profiledetails.apiToken
      }
      else {
        this.glob.profileInfo.apiToken = profiledetails.sessionToken
      }
      if (profiledetails.sessionId != null) {
        this.glob.profileInfo.sessionId = profiledetails.sessionId
      }
      else {
        this.glob.profileInfo.sessionId = profiledetails.id
      }
      if (this.glob.roledetail == 'admin') {
        this.glob.getEmployeeMenus()
      }
      else {
        this.glob.globalmenu = [];
      }
      this.glob.refreshToken()
        .then(data => {
          this.glob.scheduleRefresh();
        })
        .catch(err => {
        })
    }
    else {
      this.router.navigateByUrl('/login');
    }
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  ngAfterViewInit() {
    document.getElementById("preloader").classList.add("hide");
  }

}

