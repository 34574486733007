<div class="dropdown d-inline-block">
   
    <!-- <span class="fa fa-sign-out"> -->

        <a class="  fa fa-sign-out mt-2" id="user-menu" data-toggle="dropdown" aria-haspopup="true" style="font-size: 20px;color: black;"
        aria-expanded="false">
       
    </a>
                       
    <!-- </span> -->
    <div class="dropdown-menu dropdown-menu-right rounded-0 mt-3 p-0 box-shadow" aria-labelledby="user-menu">
        <div class="user-menu">
            <div class="dropdown-header text-center"><strong>Account</strong></div>
            <button class="dropdown-item mb-1 mt-2" (click)="Logout()"><i class="fa fa-power-off mr-2"></i>Log
                out</button>
        </div>
    </div>
</div>

