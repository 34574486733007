import { Component, OnInit } from '@angular/core';
import { FilterSettingsModel, QueryCellInfoEventArgs } from '@syncfusion/ej2-angular-grids';
// import { Tooltip } from '@syncfusion/ej2-angular-popups';

import { Observable, Subscription, interval, of } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { loginDetails } from '../login/login.model';
import { Tooltip } from '@syncfusion/ej2-angular-popups';

@Component({
  selector: 'app-global',
  templateUrl: './global.component.html',
  styleUrls: ['./global.component.scss']
})
export class GlobalComponent implements OnInit {
  public isCustomerAdmin: boolean = false;
  public roledetail: string = "admin";
  profileInfo: loginDetails;
  LoginUserId: any = '';
  public token: any;
  jobSummaryViewPage: any = [];
  refreshSubscription: Subscription;
  JobSummaryView: any = [];
  jobRecordDetails: any = [];
  JobShippingDetails: any = [];
  initialPage = { pageSizes: ['50', '100', '150', '200'], pageSize: 50 }
  public filterOptions: FilterSettingsModel = { type: 'Excel' };
  constructor(public http: HttpClient, private router: Router, private datePipe: DatePipe) { }

  ngOnInit() { }
  tooltip(args: QueryCellInfoEventArgs) {
    if (args.column.headerText === 'Item Status' && args.data['yeildSign'] == "green") {
      const tooltip: Tooltip = new Tooltip({
        // content: args.data['vendorName'].toString()+', '+args.data['salesOrderNumber'].toString()+', '+args.data['itemStatus'].toString()
        content: 'The scheduled shipping date + transportation time + prep time is equal to or earlier then required date'
      }, args.cell as HTMLTableCellElement);
    }
    if (args.column.headerText === 'Item Status' && args.data['yeildSign'] == "yellow") {
      const tooltip: Tooltip = new Tooltip({
        content: 'The scheduled shipping date + transportation time + prep time is plus/ or minus 3 days from your require date'
      }, args.cell as HTMLTableCellElement);
    }
    if (args.column.headerText === 'Item Status' && args.data['yeildSign'] == "orange") {
      const tooltip: Tooltip = new Tooltip({
        content: 'Additional information required to release order'
      }, args.cell as HTMLTableCellElement);
    }
    if (args.column.headerText === 'Item Status' && args.data['yeildSign'] == "red") {
      const tooltip: Tooltip = new Tooltip({
        content: 'The scheduled shipping date + transportation time + prep time is later than your required date'
      }, args.cell as HTMLTableCellElement);
    }
    if (args.column.headerText === 'Item Status' && args.data['yeildSign'] == "brown") {
      const tooltip: Tooltip = new Tooltip({
        content: 'Manufacturer has not provided an estimated ship yet'
      }, args.cell as HTMLTableCellElement);
    }
    if (args.column.headerText === 'Item Status' && args.data['yeildSign'] == "blue") {
      const tooltip: Tooltip = new Tooltip({
        content: 'All material has arrived at CX'
      }, args.cell as HTMLTableCellElement);
    }
    if (args.column.headerText === 'Item Status' && args.data['yeildSign'] == "purple") {
      const tooltip: Tooltip = new Tooltip({
        content: 'All material has been delivered to the site '
      }, args.cell as HTMLTableCellElement);
    }
    if (args.column.headerText === 'Item Status' && args.data['yeildSign'] == "NULL") {
      const tooltip: Tooltip = new Tooltip({
        content: 'This item will not be shipping'
      }, args.cell as HTMLTableCellElement);
    }
    if (args.column.headerText === 'PO Status' && args.data['itemStatus'] == 'REL') {
      const tooltip: Tooltip = new Tooltip({
        content: 'Released to Vendor'
      }, args.cell as HTMLTableCellElement);
    }

    if (args.column.headerText === 'PO Status' && args.data['itemStatus'] == 'NREL') {
      const tooltip: Tooltip = new Tooltip({
        content: 'Not released to Vendor'
      }, args.cell as HTMLTableCellElement);
    }

    if (args.column.headerText === 'PO Status' && args.data['itemStatus'] == 'HFR') {
      const tooltip: Tooltip = new Tooltip({
        content: 'Hold for Release to Vendor – Awaiting Approval'
      }, args.cell as HTMLTableCellElement);
    }

    if (args.column.headerText === 'PO Status' && args.data['itemStatus'] == 'PCO') {
      const tooltip: Tooltip = new Tooltip({
        content: 'Pending Change Order – Awaiting Approval'
      }, args.cell as HTMLTableCellElement);
    }

    if (args.column.headerText === 'Shipping Details') {
      const tooltip: Tooltip = new Tooltip({
        content: 'Shipping Details'
      }, args.cell as HTMLTableCellElement);
    }
    if (args.column.headerText === 'Catalog Number / Description') {
      const tooltip: Tooltip = new Tooltip({
        content: args.data['descriptionAdditional']
      }, args.cell as HTMLTableCellElement);
    }
    if (args.column.headerText === 'Notes') {
      const tooltip: Tooltip = new Tooltip({
        content: args.data['notes']
      }, args.cell as HTMLTableCellElement);
    }
    if (args.column.headerText == 'E-mail Status') {
      const tooltip: Tooltip = new Tooltip({
        content: args.data['emailStatus']
      }, args.cell as HTMLTableCellElement);
    }
  }

  // // header cell info 
  headerCellInfo(args) {
    if (args.cell.column.field === 'type') {
      const toolcontent = args.cell.column.headerText;
      const tooltip: Tooltip = new Tooltip({
        content: toolcontent
      });
      tooltip.appendTo(args.node);
    }
    if (args.cell.column.field === 'vendorName') {
      const toolcontent = args.cell.column.headerText;
      const tooltip: Tooltip = new Tooltip({
        content: toolcontent
      });
      tooltip.appendTo(args.node);
    }
    if (args.cell.column.field === 'vendorName') {
      const toolcontent = args.cell.column.headerText;
      const tooltip: Tooltip = new Tooltip({
        content: toolcontent
      });
      tooltip.appendTo(args.node);
    }
    if (args.cell.column.field === 'salesOrderNumber') {
      const toolcontent = args.cell.column.headerText;
      const tooltip: Tooltip = new Tooltip({
        content: toolcontent
      });
      tooltip.appendTo(args.node);
    }
    if (args.cell.column.field === 'itemStatus') {
      const toolcontent = args.cell.column.headerText;
      const tooltip: Tooltip = new Tooltip({
        content: toolcontent
      });
      tooltip.appendTo(args.node);
    }
    if (args.cell.column.field === 'orderQty') {
      const toolcontent = args.cell.column.headerText;
      const tooltip: Tooltip = new Tooltip({
        content: toolcontent
      });
      tooltip.appendTo(args.node);
    }
    if (args.cell.column.field === 'vendorCxStock') {
      const toolcontent = args.cell.column.headerText;
      const tooltip: Tooltip = new Tooltip({
        content: toolcontent
      });
      tooltip.appendTo(args.node);
    }
    if (args.cell.column.field === 'qtyOnsite') {
      const toolcontent = args.cell.column.headerText;
      const tooltip: Tooltip = new Tooltip({
        content: toolcontent
      });
      tooltip.appendTo(args.node);
    }
    if (args.cell.column.field === 'descriptionAdditional') {
      const toolcontent = args.cell.column.headerText;
      const tooltip: Tooltip = new Tooltip({
        content: toolcontent
      });
      tooltip.appendTo(args.node);
    }

    if (args.cell.column.field === 'yeildSign') {
      const toolcontent = args.cell.column.headerText;
      const tooltip: Tooltip = new Tooltip({
        content: toolcontent
      });
      tooltip.appendTo(args.node);
    }

    if (args.cell.column.field === 'notes') {
      const toolcontent = args.cell.column.headerText;
      const tooltip: Tooltip = new Tooltip({
        content: toolcontent
      });
      tooltip.appendTo(args.node);
    }

    if (args.cell.column.field === 'requiredDt') {
      const toolcontent = args.cell.column.headerText;
      const tooltip: Tooltip = new Tooltip({
        content: toolcontent
      });
      tooltip.appendTo(args.node);
    }
    if (args.cell.column.field === 'shipments') {
      const toolcontent = args.cell.column.headerText;
      const tooltip: Tooltip = new Tooltip({
        content: toolcontent
      });
      tooltip.appendTo(args.node);
    }
    if (args.cell.column.field === 'releasedDate') {
      const toolcontent = args.cell.column.headerText;
      const tooltip: Tooltip = new Tooltip({
        content: toolcontent
      });
      tooltip.appendTo(args.node);
    }
    if (args.cell.column.field === 'emailStatus') {
      const toolcontent = args.cell.column.headerText;
      const tooltip: Tooltip = new Tooltip({
        content: toolcontent
      });
      tooltip.appendTo(args.node);
    }
  }

  logout() {
    let LoginTypeVal = localStorage.getItem("LoginTYpeCON");
    localStorage.removeItem('Tocken');
    localStorage.clear();

    localStorage.setItem('LoginTYpeCON', JSON.stringify(LoginTypeVal));
  }

  refreshToken(): Promise<void> {
    return new Promise((resolve, reject) => {
      let url = "api/JobDetails/RefreshSession";

      this.http.post(url, {}).subscribe((response: any) => {

        localStorage.setItem("UserDetails", JSON.stringify(response));
        this.profileInfo = response;

        this.profileInfo.apiToken = response.sessionToken;
        this.profileInfo.sessionId = response.id;
        this.profileInfo.refreshToken = response.refreshToken;
        this.profileInfo.loginUserId = response.sessionUser.userName;
        this.profileInfo.memberId = 0;

        this.profileInfo.UserName = response.sessionUser.userName;
        let LoginTYpeCON = localStorage.getItem('LoginTYpeCON');
        // this.EcllipseAPILogin();


        this.roledetail = LoginTYpeCON;

        resolve(response)
      },
        (err: HttpErrorResponse) => {
       
          alert("Login Session Expired.Please Login Again")
          localStorage.clear();
          this.router.navigateByUrl("/login");
          reject(err);
        });
    });


  }
  scheduleRefresh() {
    this.refreshSubscription = interval(1000 * 60 * 20).subscribe(() => {
      this.refreshToken().catch(() => {
        this.refreshSubscription.unsubscribe();
      });
    });
  }

  getDateTimeString(date: any): string {

    var tzoffset = (new Date(date)).getTimezoneOffset() * 60000;
    //offset in milliseconds  
    return (new Date(new Date(date).getTime() - tzoffset)).toISOString().slice(0, -1);
  }

  getDateTimeString1(date: any): string {
    let datess = this.datePipe.transform(new Date(date), 'yyyy-MM-dd HH:mm:ss')
    return (new Date(datess)).toISOString();
  }
  
  globalmenu:any=[];
  getEmployeeMenus() {
    let EM = [
      {
        "id": 60,
        "title": "Job Dashboard",
        "routerLink": "/messageadmin",
        "href": null,
        "icon": "tachometer",
        "target": null,
        "parentId": 0,
        "hasSubMenu": false
      },
      {
        "id": 60,
        "title": "Manager",
        "routerLink": "/manager-detail",
        "href": null,
        "icon": "briefcase",
        "target": null,
        "parentId": 0,
        "hasSubMenu": false
      },
      {
        "id": 60,
        "title": "User",
        "routerLink": "/user-detail",
        "href": null,
        "icon": "user",
        "target": null,
        "parentId": 0,
        "hasSubMenu": false
      },
      {
        "id": 60,
        "title": "Vendor",
        "routerLink": "/jobvendor",
        "href": null,
        "icon": "bookmark",
        "target": null,
        "parentId": 0,
        "hasSubMenu": false
      }

    ];
    
    let menu: any = []
    let i: number = 1;
    EM.forEach(element => {
      let temp: any = {}
      temp.id = i;
      temp.title = element.title;
      temp.routerLink = element.routerLink;
      temp.href = null;
      temp.icon = element.icon;
      temp.target = null;
      temp.parentId = 0;
     
     
        temp.hasSubMenu = false;
     
      menu.push(temp);
      i++;
    });
    this.globalmenu = menu;

  
   
  }
}


