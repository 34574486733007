<nav class="navbar box-shadow app-header" [class.fixed-top]="settings.theme.navbarIsFixed"
    [class.logo-visible]="showInfoContent">
    <div class="d-flex flex-column w-100">
        <div class="row d-flex justify-content-start align-items-center top-navbar">
           <div class="col-lg-9 col-md-7">
            <span *ngIf="glob.roledetail =='admin'" class="navbar-text menu-icon transition ml-3 mr-3" [ngClass]="{'open': settings.theme.showMenu}"
            (click)="settings.theme.showMenu = !settings.theme.showMenu">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
        </span>
        <a class="logo" (click)="closeSubMenus()">
            <img src="/assets/img/Cx_RGB_horizontal.png" alt="Girl in a jacket" width="120" height="38">
        </a>

           </div>
           <div class="col-lg-3 col-md-4">
            <div class="row ">
                <div class="col-sm-2 pr-0 col-lg-2  pl-0 pb-0">
                    <img src="../../../assets/img/avatars/man.jpg"
                        style="border-radius:0%;width:30px;height:30px;margin:0 auto;display:block; text-align: right;" />
                </div>
                <div class="col-sm-6 col-lg-8  d-flex pr-0">
                    <h6 class="mt-2 mr-2"  style="cursor: pointer; word-wrap: none;font-weight: bold;color: black;">
                        {{glob.profileInfo.UserName}}</h6>
                        <app-user-menu></app-user-menu>
                </div>
                <!-- <div class="col-lg-3 col-md-2">
                    
                   
                   
                </div> -->
               

               </div>
           </div>

          
        </div>
        <div class="horizontal-menu-outer" *ngIf="settings.theme.menu == 'horizontal'">
            <app-horizontal-menu *ngIf="showHorizontalMenu" [menuItems]="menuItems"></app-horizontal-menu>
            <app-vertical-menu *ngIf="!showHorizontalMenu" [menuItems]="menuItems"></app-vertical-menu>
        </div>
    </div>
</nav>