import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { ToastrModule } from 'ngx-toastr';
import { PipesModule } from './theme/pipes/pipes.module';
import { AppSettings } from './app.settings';
import { AppComponent } from './app.component';

import { HeaderComponent } from './theme/components/header/header.component';
import { FooterComponent } from './theme/components/footer/footer.component';
import { SidebarComponent } from './theme/components/sidebar/sidebar.component';
import { HorizontalMenuComponent } from './theme/components/menu/horizontal-menu/horizontal-menu.component';
import { BreadcrumbComponent } from './theme/components/breadcrumb/breadcrumb.component';
import { BackTopComponent } from './theme/components/back-top/back-top.component';
import { FullScreenComponent } from './theme/components/fullscreen/fullscreen.component';
import { UserMenuComponent } from './theme/components/user-menu/user-menu.component';

import { HttpClientModule, HTTP_INTERCEPTORS, HttpClientJsonpModule } from '@angular/common/http';

import { GridModule,  ExcelExportService, PdfExportService } from '@syncfusion/ej2-angular-grids';
import { ToolbarModule } from '@syncfusion/ej2-angular-navigations';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { MyDatePickerModule } from 'mydatepicker';
import { DatePipe, CommonModule, LocationStrategy, HashLocationStrategy } from '@angular/common'
import { FileUploadModule } from 'ng2-file-upload';
import { MultiSelectModule } from '@syncfusion/ej2-angular-dropdowns';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgSelectModule } from '@ng-select/ng-select';
import {  DialogModule } from '@syncfusion/ej2-angular-popups';
import { FormsModule } from '@angular/forms';
import { NumericTextBoxModule, NumericTextBoxAllModule } from '@syncfusion/ej2-angular-inputs';

import { CanDeactivateGuard } from 'src/app/services/deactivate.service';
import { VerticalMenuComponent } from './theme/components/menu/vertical-menu/vertical-menu.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { AppRoutingModule } from './app-routing.module';



import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { NgxUiLoaderConfig, NgxUiLoaderModule, SPINNER } from 'ngx-ui-loader';
import { ViewsModule } from './views/views.module';
import { ViewsComponent } from './views/views.component';
import { GlobalComponent } from './views/global/global.component';
import { AuthProvider } from './auth.service';

//const ngxUiLoaderConfig: NgxUiLoaderConfig = {  fgsSize: 60,  fgsType: SPINNER.threeStrings,  fgsColor: 'lightBlue'  };
const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  fgsType: SPINNER.threeStrings,
  fgsSize:100,
  pbThickness: 5,
  logoUrl:'assets/img/LOADING.png',
  logoPosition:"center-center",
  logoSize:50,
  gap:-70,
  fgsPosition:"center-center"  
};
@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
   FormsModule,
    DragDropModule,
    NgxChartsModule,
    PerfectScrollbarModule,
    NgbModule,
    NgxUiLoaderModule,
    MultiselectDropdownModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    ToastrModule.forRoot(),
    PipesModule,
    HttpClientModule,
    GridModule,
    ToolbarModule,
    AngularMyDatePickerModule,
    MyDatePickerModule,
    FileUploadModule,
    MultiSelectModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxUiLoaderModule,
    ViewsModule,
    NgSelectModule,
    DialogModule,
    NumericTextBoxModule,
    NumericTextBoxAllModule,
    NgbModule,
    CommonModule,
    HttpClientJsonpModule,
    AppRoutingModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  declarations: [
    AppComponent,
    ViewsComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    VerticalMenuComponent,
    HorizontalMenuComponent,
    BreadcrumbComponent,
    BackTopComponent,
    FullScreenComponent,
    UserMenuComponent,
   
  ],
  providers: [
   
    CanDeactivateGuard,
    AppSettings,
    DatePipe,
    ExcelExportService,
    PdfExportService,
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    GlobalComponent,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthProvider,
      multi: true,
    },
  
    { provide: LocationStrategy, useClass: HashLocationStrategy,  }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
