import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules  } from '@angular/router';
import { ViewsComponent } from './views/views.component';
export const routes: Routes = [
  {
    path: '',
    component: ViewsComponent,
    children:[
      { path: 'messageadmin', loadChildren: () => import('./views/messageadmin/messageadmin.module').then(m => m.MessageAdminModule) },
     
      { path: 'user-detail', loadChildren: () => import('./views/user-detail/user-detail.module').then(m => m.UserDetailModule) },    
     
     
      { path: 'jobvendor', loadChildren: () => import('./views/jobvendor/jobvendor.module').then(m => m.JobVendorModule) },          
   
      { path: 'manager-detail', loadChildren: () => import('./views/manager-detail/manager-detail.module').then(m => m.ManagerDetailModule) },          
      // {
      //   path: 'job-dashboard',
      //   loadChildren: './views/job-dashboard/job-dashboard.module#JobDashboardModule'
      // },
     
    ]
  },
  { path: 'login', loadChildren: () => import('./views/login/login.module').then(m => m.LoginModule) },
];
 
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: 'legacy',
    })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
