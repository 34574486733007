import { Menu } from './menu.model';
 
export const verticalMenuItems = [
    new Menu (1, 'Job Dashboard', '/messageadmin', null, 'tachometer', null, false, 0),
    new Menu (2, 'Manager', "/manager-detail", null, 'briefcase', null, false, 0),
    new Menu (3, 'User', '/user-detail', null, 'user', null, false, 0),    
    new Menu (4, 'Vendor', '/jobvendor', null, 'bookmark', null, false, 0),  
 
]
 
export const horizontalMenuItems = [
    new Menu (1, 'Job Dashboard', '/messageadmin', null, 'tachometer', null, false, 0),
    new Menu (2, 'Manager', "/manager-detail", null, 'briefcase', null, false, 0),
    new Menu (3, 'User', '/user-detail', null, 'user', null, false, 0),    
    new Menu (4, 'Vendor', '/jobvendor', null, 'bookmark', null, false, 0),
 
]