<div class="app" [ngClass]="settings.theme.skin" [class.navbar-fixed]="settings.theme.navbarIsFixed"
    [class.sidebar-fixed]="settings.theme.sidebarIsFixed" [class.horizontal-menu]="settings.theme.menu == 'horizontal'"
    [class.compact]="settings.theme.menuType == 'compact'" [class.mini]="settings.theme.menuType == 'mini'"
    [class.menu-hide]="!settings.theme.showMenu">
    <div class="wrapper">
        <router-outlet></router-outlet>
    </div>
</div>
<div>
    <ngx-ui-loader></ngx-ui-loader>
   
</div>

