<div class="wrapper-inner" [ngClass]="{ 'vertical-menu-tooltip-place': (settings.theme.menuType == 'mini' && settings.theme.menu == 'vertical'),
                                        'horizontal-menu-tooltip-place': (settings.theme.menuType == 'mini' && settings.theme.menu == 'horizontal') }">
   
    <app-header></app-header>
    <div class="d-flex h-100" [ngClass]="{'flex-row': settings.theme.menu == 'vertical', 'flex-column': settings.theme.menu != 'vertical'}">
        <app-sidebar *ngIf="settings.theme.menu == 'vertical'"></app-sidebar>
        <div class="main-content">
            <router-outlet></router-outlet>              
        </div>
    </div>  
</div>
<app-back-top position="200"></app-back-top>