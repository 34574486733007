
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GlobalComponent } from './views/global/global.component';
  
@Injectable()
export class AuthProvider implements HttpInterceptor {
    url: String = environment.baseUrl;
    tockendata: any = {};
    tokenstring: any;
    constructor(public glob: GlobalComponent) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.tokenstring = this.glob.profileInfo.sessionId;
        const authreq = request.clone({
            url: this.url + request.url,
            setHeaders: {
                'Authorization': `Bearer ` + this.glob.profileInfo.apiToken,
                'Sessionid': this.tokenstring,
                'RefreshToken': this.glob.profileInfo.refreshToken,
            },
        });
        return next.handle(authreq)
    }
}
export class Constants {
    public static get Task_Publish_Url(): string { return "http://localhost:5000/api/TaskRules/TaskPublishService?"; };
    public static get Message_Publish_Url(): string { return "http://localhost:5000/api/TaskRules/MessagePublishService?"; };
}

