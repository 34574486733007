import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GlobalComponent } from 'src/app/views/global/global.component';

import { URLSearchParams } from 'url';


@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserMenuComponent implements OnInit {
  private onDestroy$: Subject<void> = new Subject<void>();
  islogoutOfAllSessions: boolean = false;
  constructor( private router: Router, public toasterService: ToastrService,public glob:GlobalComponent) { }

  ngOnInit() {
   
  }
  Logout() {

    // let LoginTypeVal =JSON.parse(localStorage.getItem("LoginTYpeCON")); 
    // localStorage.removeItem('Tocken');
    // localStorage.clear();
    this.glob.logout();
    this.router.navigate(['/login']);
    // localStorage.setItem('LoginTYpeCON', JSON.stringify(LoginTypeVal));
  }
}